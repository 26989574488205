import styled, { keyframes } from "styled-components";

interface IRecommendedTextProps {
  recommendedImpressions: number;
  recommendedPrice: number;
}

export const RecommendedText = ({
  recommendedImpressions,
  recommendedPrice,
}: IRecommendedTextProps) => {
  return (
    <InputWrapper>
      <Text>
        För optimal målgruppspenetration behöver du{" "}
        <Numbers>{recommendedImpressions}</Numbers> st målgruppsstyrda
        annonsvisningar per månad till en kostnad av{" "}
        <Numbers>{recommendedPrice}</Numbers> SEK/månad
      </Text>
    </InputWrapper>
  );
};
const inAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20%);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
`;

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  color: #00a0d6;
  margin-top: 0;
  animation: ${inAnimation} 500ms ease-in;
`;

const Text = styled.p`
  margin-top: 0;
  margin-bottom: 10px;
  color: #005b7a;
`;

const Numbers = styled.span`
  font-family: PostNordSansMedium;
  font-size: 16px;
`;
