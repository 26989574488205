import styled, { keyframes } from "styled-components";

interface IClientProposalProps {
  clientImpressions: number;
  percentOfRecommended: number;
  setShowStatementText: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ClientProposal = ({
  clientImpressions,
  percentOfRecommended,
  setShowStatementText,
}: IClientProposalProps) => {
  return (
    <ProposalWrapper>
      <ProposalText>
        Med din budget kommer du få <Numbers>{clientImpressions}</Numbers> st
        målgruppsstyrda annonsvisningar per månad och täcka{" "}
        <Numbers>{percentOfRecommended.toFixed(1)}</Numbers> procent av ditt
        upptagningsområde.
      </ProposalText>

      <ShowStatementWrapper>
        <ShowHeader>Visa sammanställlning</ShowHeader>
        <Circle onClick={() => setShowStatementText(true)}>
          <div id="cta">
            <span className="arrow primera next bounceAlpha"></span>
            <span className="arrow segunda next bounceAlpha"></span>
          </div>
        </Circle>
      </ShowStatementWrapper>
    </ProposalWrapper>
  );
};

const inAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20%);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
`;

const ProposalWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  color: #00a0d6;
  margin: 0;
  animation: ${inAnimation} 500ms ease-in;
`;

const ProposalText = styled.p`
  margin: 0;
  margin-bottom: 10px;
  color: #005b7a;
`;

const ShowStatementWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: -45px;
`;

const ShowHeader = styled.h3`
  font-size: 16px;
  transform: translateY(-35px);
`;

const Circle = styled.div`
  position: absolute;
  background-color: #00a0d6;
  border: 2px solid #45d0ff;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  transform: rotate(90deg);
  cursor: pointer;
`;

const Numbers = styled.span`
  font-family: PostNordSansMedium;
  font-size: 16px;
`;
