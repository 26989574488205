import { ClientCpmLevels } from "./clientCpmLevels";

interface IGetClientImpressionsProps {
  budget: number;
  recommendedPrice: number;
  recommendedImpressions: number;
  setClientImpressions: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  setPercentOfRecommended: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  setCpm: React.Dispatch<React.SetStateAction<number>>;
}

export const getClientImpressions = ({
  budget,
  recommendedPrice,
  recommendedImpressions,
  setClientImpressions,
  setPercentOfRecommended,
  setCpm,
}: IGetClientImpressionsProps) => {
  const shareOfRecommended = budget / recommendedPrice;
  const cpm = ClientCpmLevels({
    budget: budget,
    setCpm: setCpm,
  });
  const impressions = (budget / cpm) * 1000;
  setClientImpressions(Math.round(impressions));
  setPercentOfRecommended(shareOfRecommended * 100);
};
