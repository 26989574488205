import { useEffect, useState } from "react";
import styled from "styled-components";
import { ClientBudget } from "./clientBudget";
import { ClientCpmLevels } from "./clientCpmLevels";
import { getClientImpressions } from "./getClientImpressions";
import { getRecommendedPrice } from "./getRecommendedPrice";
import { RecommendedText } from "./recommendedText";
import { ClientProposal } from "./clientProposal";
import { Target } from "./target";
import { Statement } from "./statement";

export const BudgetForm = () => {
  const [targetGroup, setTargetGroup] = useState<number>();
  const [selectedFrequency, setSelectedFrequency] = useState<number>(1);
  const [recommendedImpressions, setRecommendedImpressions] =
    useState<number>();
  const [recommendedPrice, setRecommendedPrice] = useState<number>();
  const [recommendedCpm, setRecommendedCpm] = useState<number>(200);
  const [budget, setBudget] = useState<number | null>(null);
  const [clientImpressions, setClientImpressions] = useState<number>();
  const [percentOfRecommended, setPercentOfRecommended] = useState<number>();
  const [costPerODR, setCostPerODR] = useState<number>();
  const [cpm, setCpm] = useState<number>(200);
  const [showStatementText, setShowStatementText] = useState<boolean>(false);

  useEffect(() => {
    if (targetGroup)
      setRecommendedImpressions(
        Math.round(targetGroup * (selectedFrequency * 4.33))
      );

    if (recommendedImpressions) {
      getRecommendedPrice({
        recommendedCpm,
        recommendedImpressions,
        setRecommendedPrice,
        setRecommendedCpm,
      });

      if (budget && recommendedPrice) {
        getClientImpressions({
          budget,
          recommendedPrice,
          recommendedImpressions,
          setClientImpressions,
          setPercentOfRecommended,
          setCpm,
        });
      }
    }

    if (budget) {
      ClientCpmLevels({ budget, setCpm });
      setCostPerODR(cpm / 1000);
    }
  }, [
    targetGroup,
    selectedFrequency,
    recommendedImpressions,
    recommendedPrice,
    budget,
    clientImpressions,
    percentOfRecommended,
    cpm,
  ]);

  useEffect(() => {
    if (!budget) setShowStatementText(false);
  }, [budget]);

  return (
    <FormWrapper>
      <FormContainer>
        <Header>
          Vilken månatlig mediebudget behövs för din Digitala ODR?
        </Header>
        <Forms>
          <Target
            setTargetGroup={setTargetGroup}
            setSelectedFrequency={setSelectedFrequency}
          />
          {targetGroup &&
            selectedFrequency &&
            recommendedImpressions &&
            recommendedPrice && (
              <RecommendedText
                recommendedImpressions={recommendedImpressions}
                recommendedPrice={recommendedPrice}
              />
            )}
        </Forms>
        <Forms>
          <ClientBudget setBudget={setBudget} disabled={!targetGroup} />
          {clientImpressions &&
            percentOfRecommended &&
            costPerODR &&
            budget && (
              <ClientProposal
                clientImpressions={clientImpressions}
                percentOfRecommended={percentOfRecommended}
                setShowStatementText={setShowStatementText}
              />
            )}
        </Forms>
        {clientImpressions &&
          percentOfRecommended &&
          costPerODR &&
          budget &&
          showStatementText && (
            <Forms>
              <Statement
                clientImpressions={clientImpressions}
                percentOfRecommended={percentOfRecommended}
                budget={budget}
                costPerODR={costPerODR}
              />
            </Forms>
          )}
      </FormContainer>
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  width: 90%;
  min-height: 90vh;
  padding: 5vh 5%;
`;

const FormContainer = styled.div`
  display: grid;
  place-items: center;
  gap: 30px;
`;

const Header = styled.h1`
  font-family: PostNordSansBold;
  text-align: center;
  margin-bottom: 0;
  width: 1000px;
  font-size: 50px;

  @media (max-width: 750px) {
    width: 100%;
    font-size: 35px;
  }
  @media only screen and (min-width: 750px) and (max-width: 1280px) {
    width: 700px;
    font-size: 45px;
  }
`;

const Forms = styled.div`
  display: grid;
  gap: 20px;
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 5px 10px 10px #d3d3d342;
  width: 700px;
  @media (max-width: 750px) {
    width: 90%;
  }
  @media only screen and (min-width: 750px) and (max-width: 1280px) {
    width: 500px;
  }
`;
