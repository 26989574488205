import { ClientCpmLevels } from "./clientCpmLevels";

interface IGetRecommendedPriceProps {
  recommendedCpm: number;
  recommendedImpressions: number;
  setRecommendedPrice: React.Dispatch<React.SetStateAction<number | undefined>>;
  setRecommendedCpm: React.Dispatch<React.SetStateAction<number>>;
}

export const getRecommendedPrice = ({
  recommendedCpm,
  recommendedImpressions,
  setRecommendedPrice,
  setRecommendedCpm,
}: IGetRecommendedPriceProps) => {
  const price = (recommendedCpm * recommendedImpressions) / 1000;
  ClientCpmLevels({
    budget: price,
    setCpm: setRecommendedCpm,
  });

  setRecommendedPrice(Math.round(price));
};
