interface IClientCpmLevelsProps {
  budget: number;
  setCpm: React.Dispatch<React.SetStateAction<number>>;
}

export const ClientCpmLevels = ({ budget, setCpm }: IClientCpmLevelsProps) => {
  const cpmLevels = [
    { budgetLevel: 1100000, cpm: 140 },
    { budgetLevel: 900000, cpm: 145 },
    { budgetLevel: 700000, cpm: 150 },
    { budgetLevel: 500000, cpm: 160 },
    { budgetLevel: 300000, cpm: 170 },
    { budgetLevel: 100000, cpm: 180 },
    { budgetLevel: 40000, cpm: 190 },
  ];
  for (let i = 0; i < cpmLevels.length; i++) {
    if (budget > cpmLevels[i].budgetLevel) {
      setCpm(cpmLevels[i].cpm);
      return cpmLevels[i].cpm;
    } else {
      setCpm(200);
    }
  }
  return 200;
};
