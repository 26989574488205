import styled from "styled-components";

interface ITargetProps {
  setTargetGroup: React.Dispatch<React.SetStateAction<number | undefined>>;
  setSelectedFrequency: React.Dispatch<React.SetStateAction<number>>;
}

export const Target = ({
  setTargetGroup,
  setSelectedFrequency,
}: ITargetProps) => {
  const frequencyOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <div>
      <InputWrapper>
        <Label htmlFor="targetGroup">
          Hur många målgruppspersoner finns i ditt upptagningsområde?
        </Label>
        <Input
          type="number"
          name="targetGroup"
          onChange={(e) => setTargetGroup(parseInt(e.target.value))}
          min="0"
        />
      </InputWrapper>
      <InputWrapper>
        <Label htmlFor="select">
          Varumärkespenetration per målgruppsperson och vecka
        </Label>
        <Select
          name="select"
          onChange={(e) => setSelectedFrequency(parseInt(e.target.value))}
        >
          {frequencyOptions.map((option) => {
            return (
              <option value={option} key={option}>
                {option}
              </option>
            );
          })}
        </Select>
      </InputWrapper>
    </div>
  );
};

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

const Input = styled.input`
  background-color: #00a0d6;
  border: 2px solid #45d0ff;
  border-radius: 15px;
  height: 30px;
  padding: 10px;
  color: #fff;
  margin-bottom: 20px;
  font-size: 16px;
`;

const Select = styled.select`
  background-color: #00a0d6;
  border: 2px solid #45d0ff;
  border-radius: 15px;
  color: #fff;
  height: 52px;
  padding: 5px;
  font-size: 16px;
`;

const Label = styled.label`
  color: #00a0d6;
  font-size: 18px;
  margin-bottom: 5px;
`;
