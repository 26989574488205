import styled, { keyframes } from "styled-components";

interface IClientProposalProps {
  clientImpressions: number;
  percentOfRecommended: number;
  budget: number;
  costPerODR: number;
}

export const Statement = ({
  clientImpressions,
  percentOfRecommended,
  budget,
  costPerODR,
}: IClientProposalProps) => {
  return (
    <StatementWrapper>
      <Header>Sammanställning</Header>
      <List>
        <StatementNumber>1</StatementNumber>Budget: {budget} kr
      </List>
      <List>
        <StatementNumber>2</StatementNumber>Antal visningar per månad:{" "}
        {clientImpressions} st
      </List>
      <List>
        <StatementNumber>3</StatementNumber>Kostnad per Digital ODR:{" "}
        {costPerODR} kr
      </List>
      <List>
        <StatementNumber>4</StatementNumber>Täckning av målgrupp:{" "}
        {percentOfRecommended.toFixed(1)} %
      </List>
    </StatementWrapper>
  );
};

const inAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20%);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
`;

const StatementWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  color: #00a0d6;
  margin: 0;
  animation: ${inAnimation} 500ms ease-in;
`;

const Header = styled.span`
  font-family: PostNordSansMedium;
  color: #00a0d6;
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 20px;
`;

const List = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #00a0d6;
  list-style-type: none;
  margin: 5px 0;
`;

const StatementNumber = styled.div`
  display: grid;
  place-items: center;
  background-color: #00a0d6;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  font-family: PostNordSansBold;
  margin-right: 10px;
`;
