import IconRight from "../Img/IconRight.svg";
import IconLeft from "../Img/IconLeft.svg";
import styled from "styled-components";

export const BackgroundIcons = () => {
  return (
    <BackgroundWrapper>
      <LeftIcon src={IconLeft} alt="" />
      <RightIcon src={IconRight} alt="" />
    </BackgroundWrapper>
  );
};

const BackgroundWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100vh;
  position: fixed;
  pointer-events: none;
  z-index: -1;
`;

const LeftIcon = styled.img`
  height: 25%;
  margin-top: 50vh;
  transform: translateY(-50%);
  @media (min-width: 500px) {
    height: 35%;
  }
  @media (min-width: 750px) {
    height: 45%;
  }
`;

const RightIcon = styled.img`
  height: 25%;
  margin-top: 50vh;
  transform: translateY(-50%);
  margin-left: auto;
  @media (min-width: 500px) {
    height: 35%;
  }
  @media (min-width: 750px) {
    height: 45%;
  }
`;
